import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';
import Layout from '../../components/Layout';
import PageWrapper from '../../components/PageWrapper';
import ServiceList from '../../components/ServicesList';
import SEO from '../../components/SEO/SEO';

export default function ServicesIndexPage() {
  const data = useStaticQuery(graphql`
      query ServicesQuery {
          allMarkdownRemark(
              filter: { frontmatter: { templateKey: { eq: "services-page" } } }
          ) {
              edges {
                  node {
                      excerpt(pruneLength: 300)
                      id
                      fields {
                          slug
                      }
                      frontmatter {
                          title
                          onlineBooking
                      }
                  }
              }
          }
      }

  `);

  const services = data.allMarkdownRemark.edges.map((x) => x.node);
  return (
    <Layout color="black">
      <SEO
        title="Tjenester"
      />
      <PageWrapper title="Tjenester">
        {services
          ? <ServiceList services={services}/>
          : <h1>Vi fant ingen tjenester akkurat nå, prøv igjen senere.</h1>
        }
      </PageWrapper>
    </Layout>
  );
}
