import React from 'react';
import { Link } from 'gatsby';

const ServiceList = (props) => {

  const services = props.services;

  if (!services) {
    return (<h1>Vi fant ingen tjenester akkurat nå, prøv igjen senere.</h1>);
  }

  return (
    <>
      {services.map(service => (
        <Link key={service.fields.slug} to={service.fields.slug}>
          <p class="service-link">
            {service.frontmatter.title}
          </p>
        </Link>
      ))}
    </>
  )
};

export default ServiceList;
